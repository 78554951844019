import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { calcCallPrem, calcPutPrem, findCallIV, findPutIV } from './func';
import SimForm from './SimForm';
import FreeSim from './FreeSim';
import About from './About';
import Policy from './Policy';
import { opChainStrg } from './fbStorage.js';
import { getDownloadURL, ref, listAll } from 'firebase/storage';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from "chart.js";
import { OPresult, evalTmp } from "./graphOptions.js"

import { EvalContext } from './context/evaldata-context.jsx';

function App() {
  let newTime = new Date().toLocaleDateString('sv-SE');//sv enable "mm-dd-yyyy" format
  Chart.register(...registerables);

  //Option Data
  const [opData, setOpdata] = useState([]);

  //Get GrandChild's simulated data for Merged graph(Calender spread)
  const { evalState } = useContext(EvalContext);

  //Merged graph no need to rerender after child graphs aleready rendered. So,useRef.
  const mergedData = useRef(null);
  let data = JSON.parse(JSON.stringify(evalTmp));//data for useref in <Line /> graph component
  data.datasets.pop();//except SQ(last data) for merged data
  //let graphOP = JSON.parse(JSON.stringify(OPresult));
  let graphOP = OPresult;

  const retrieveData = async () => {
    let nameList = [];
    let dataList = [];
    let premiumList = [];
    let dataUrl;
    let name;
    setOpdata([]);
    //get option json file list
    const listRef = ref(opChainStrg, 'json_opChain/');
    const lists = await listAll(listRef);
    const items = lists.items;
    const n = items.length;
    //get file name and data
    //enable fetch from local,set cors
    //https://firebase.google.com/docs/storage/web/download-files#cors_configuration
    for (let i = 0; i < n; i++) {
      name = items[i].name.slice(0, -5);
      name = name.slice(2);
      nameList[i] = name;
      dataUrl = await getDownloadURL(items[i]);
      dataList[i] = await fetch(dataUrl);
      dataList[i] = await dataList[i].json();
      premiumList[i] = dataList[i].map((data) => {
        return {
          Strike: data.Strike,
          CallValue: data.CallValue,
          PutValue: data.PutValue
        }
      })
    }
    //set data to state
    dataList.map((data, i) => setOpdata((opData) => [...opData, {
      Category: nameList[i] + "_" + data[0].LastDay,
      Price: data[0].Price,
      today: data[0].today,
      LastDay: data[0].LastDay,
      time: data[0].time,
      premium: premiumList[i]
    }]))
  }

  //update useRef data for graph
  const mergeGraph = () => {
    //mergedData.current.data = JSON.parse(JSON.stringify(evalTmp));
    mergedData.current.data.labels = evalState[0].labels;

    for (let i = 0; i < data.datasets.length; i++) {//length withou SQs data
      if (evalState[0].datasets[i].label === evalState[1].datasets[i].label) {

        //sum value if timing is same
        for (let j = 0; j < evalState[0].labels.length; j++) {
          mergedData.current.data.datasets[i].data[j] = evalState[0].datasets[i].data[j] + evalState[1].datasets[i].data[j];
        }
        //change labels
        mergedData.current.data.datasets[i].label = evalState[0].datasets[i].label
      } else {
        mergedData.current.data.datasets[i].data = [];
        mergedData.current.data.datasets[i].label = 'Date' + i;
      }
    }
    //mergedData.current.update();
    mergedData.current.update();
  }
  //update useRef data for graph
  const handleGraphChange = (e) => {
    e.preventDefault();
    let gr = Number(e.target.value);
    mergedData.current.options.scales.x.min = gr * -1;
    mergedData.current.options.scales.x.max = gr;
    mergedData.current.update();
  }
  const location = useLocation();

  useEffect(() => {
    retrieveData();
  }, [])

  return (
    <div>

      <div className='header'>Option Simulator&nbsp;/&nbsp;<span> オプションシミュレーター</span>
        {opData.length > 0 && location.pathname === '/' &&
          <>
            <button onClick={() => retrieveData()}>Get Data</button>
            <div className='data'>
              <div>Data @<span>{opData[0].time}(JST), {opData[0].today}</span></div>
              <div>Underlying N225Future @<span>{opData[0].Price}</span></div>
            </div>
          </>
        }
        <div className='about'>
          <Link to="/about"><button>About Us</button></Link>
          <Link to="/policy"><button className='policy'><span>Privacy Policy</span></button></Link>
        </div>
      </div>

      <div className='free-or-real'>
        {location.pathname === '/' ?
          <>
            <div className='switch-flex'>
              <div>＜ Realtime Simulation ＞</div>
              <div className='switch-free-real'>
                <Link to="/freesim">
                  <button><span>⇔ Free Simulation</span></button>
                </Link>
              </div>
            </div>
            <p>リアルタイムシミュレーションでは直近のオプション価格を元に、今後の損益シミュレーションを行うことができます。<br />
              詳細は末尾にも記載がございますが、操作概要は以下のとおりです。<br />
              <span>
                ① オプションまたは先物のポジションを追加します（※オプション価格は朝夕で2回以上更新。上部の「Get Data」で更新可能）。<br />
                ② 日時を指定してその時の損益のシミュレーションを行います。その際、ポジションのIV変化量も指定可能となります。<br />
                ③ Panel#1, #2それぞれのシミュレーションDate1～3で、日時が合う場合はMerged Graph（合成グラフ）で損益の合成表示が可能。
              </span>
            </p>
          </> : location.pathname === '/freesim' && <>
            <div className='switch-flex'>
              <div>＜ Free Simulation ＞</div>
              <div className='switch-free-real'>
                <Link to="/">
                  <button><span>⇔ Realtime Simulation</span></button>
                </Link>
              </div>
            </div>
            <p>
              フリーシミュレーションでは任意のタイミング、条件でオプションポジションを入力し、損益シミュレーションを行うことが出来ます。例えば既存ポジションがDay1、Day2を通過してDay3に新規にポジションを入力してそれ以後損益がどうなるか予想したい場合に活用できます。詳細は末尾にも記載がございますが、操作概要は以下のとおりです。<br />
              <span>
                ① オプションまたは先物のポジションを追加します（※オプションの場合、エントリ日時とその時の原資産価格も入力要）。<br />
                ② 日時、原資産価格、IV変化量を指定してシミュレーションを行います。
              </span>
            </p>
          </>
        }
      </div>
      {opData.length > 0 ?
        <>
          <Routes>
            <Route exact path="/" element={<>
              {
                opData.length > 0 &&
                <>
                  <div className='wrap-form'>
                    <SimForm num="1" ops={opData} />
                    <SimForm num="2" ops={opData} />
                  </div>
                  <div className='merged-graph'>
                    <div className='h3'>Merged Graph <br />of Result#1 and Result#2</div>
                    <button onClick={() => mergeGraph()}>merge</button>
                    <div className='range-selector'>
                      <label>Graph Range: </label>
                      <select defaultValue={1500} onChange={(e) => handleGraphChange(e)}>
                        <option key="1" >500</option>
                        <option key="2" >1000</option>
                        <option key="3" >1500</option>
                        <option key="4" >2000</option>
                        <option key="5" >3000</option>
                        <option key="6" >5000</option>
                      </select>
                    </div>
                  </div>
                  <div className="bar-chart">
                    <Line ref={mergedData} data={data} options={graphOP} />
                  </div>
                </>
              }
            </>} />
            <Route exact path="/freesim" element={<FreeSim />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/policy" element={<Policy />} />
          </Routes>
        </> : <>
          <div className='loading'>Loading<span className='loading-dots'></span></div>
        </>

      }

      <div className='free-or-real'>
        {location.pathname === '/' ?
          <>
            <div>＜当サイトの使い方＞</div>
            <p>
              <span>
                ① まずは一番上からシミュレーション用のポジションを追加します。オプションではまず対象の限月を選択します（「M」がつくのは「ミニオプション」となります）。<br />
                また、オプションの価格情報がいつ時点のものかはヘッダ部で確認できます。1日数回更新され、サイトを開いた時に直近のデータが取得されます。更新があった場合ヘッダ部の「Get Data」ボタンで最新データを取得することも可能です。<br /><br />
                ② 選択したオプションの板が表示されますので、数量を入力のうえ買または売のポジションを建てます。オプション板の下に先物入力の箇所もありますので必要に応じて先物のポジションも入力してください。<br />
                入力したポジションは「Position List」に反映されます。リストの右側にある「-」ボタンを押せばポジションを取り消すことが可能です。<br /><br />
                ③ ポジション入力後、シミュレーションパラメータを入力して予想損益分布を確認します。確認できる日時はDate1～Date3までの３つとなります。<br />
                日時およびIV（インプライドボラティリティ）の変化量（任意）を入力し、「Apply」ボタンを押します。そうしますと、その条件下における損益分布図が表示されます。<br />
                なお、グラフの横軸は原資産価格の変化量を示しております。また、ピンクのグラフはSQ時点における損益分布でデフォルトで表示されます。<br /><br />
                ④ Panel#1, #2それぞれのシミュレーションDate1～3で、日時が合う場合はMerged Graphで損益の合成表示が可能となります。異なる限月ポジション間で損益合算を見たい場合はご活用ください。
              </span>
              ※フリーシミュレーションへの切替はヘッダ部で可能。
            </p>
          </> : location.pathname === '/freesim' && <>
            <div>＜当サイトの使い方＞</div>
            <p>
              <span>
                ①オプションまたは先物のポジションを追加します（※オプションの場合、エントリ日時とその時の原資産価格も入力が必要となります）。<br /><br />
                ②Day1～Day5まで日時、原資産価格、IV変化量を指定して損益分布のシミュレーションが可能となります。<br /><br />
                ③また、Day2以後は前日のポジションを引き継ぐことも可能となります。ある程度日数が経ったあとに、新規ポジションを追加した場合に、今後損益分布がどうなるかシミュレーションをしたい場合はご活用ください。
              </span>
              ※リアルタイムシミュレーションへの切替はヘッダ部で可能。
            </p>
          </>
        }
      </div>


      <div className='footer'>
        Copyright © 2025 opsim.site. All rights reserved.
      </div>

    </div >
  );
}

export default App;
