import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Policy() {
   const navigate = useNavigate();

   const clickBack = () => {
      navigate(-1);//back to previous
   };
   const clickHome = () => {
      navigate("/");//go to Home
   };

   return (
      <div className='aboutus'>
         <h3 className='h1'>プライバシーポリシー</h3>
         <p>本文書はOption Simulator / オプションシミュレーター（https://opsime.site）における個人情報の保護、及びその適切な取り扱いについての方針を示したものです。</p>

         <p>以下で使用する”当サイト”は、Option Simulator / オプションシミュレーター（https://opsim.site）を示します。</p>
         <h3>このサイトが収集する個人データと収集の理由</h3>
         <p>当サイトにおける個人情報の取扱いについて、以下のとおりにプライバシーポリシーを定めます。</p>
         <h4>運営者情報</h4>
         <p>運営者：ぐちりマン<br />
            ブログURL：https://opsim.site<br />
            お問い合わせ：gutiriman3@gmail.com</p>
         <h4>収集する個人情報とその利用目的</h4>
         <h5>1．メールアドレス</h5>
         <p>当サイトではメールでのお問い合わせの際に、お名前（ハンドルネーム）・メールアドレス等の個人情報を確認することになります。</p>

         <p>これらの個人情報は、質問に対する回答や必要な情報をご連絡するために利用し、それ以外の目的では一切利用しません。</p>
         <h5>2．Cookie</h5>
         <p>当サイトではコンテンツの充実や安定的なサイト運営を目的に、後述するアクセス解析ツール・第三者配信事業者を利用しています。そのため、Cookieを通じて情報を収集する事があります。</p>

         <p>Cookieとは、ユーザーがサイトを訪れた際に、そのユーザーのコンピュータ内に記録されるものです。ただし記録される内容はwebコンテンツへのアクセスに関する情報であり、お名前・メールアドレス・住所・電話番号は含まれません。</p>

         <p>また、お使いのブラウザ設定からCookieを無効にすることが可能です。</p>
         <h4>個人情報の第三者への開示</h4>
         <p>個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。<br />
            ・本人のご了解がある場合<br />
            ・法令等への協力のため、開示が必要となる場合</p>
         <h4>個人情報の開示・訂正・追加・削除・利用停止</h4>
         <p>個人情報の開示・訂正・追加・削除・利用停止をご希望の場合には、ご本人であることを確認したうえで、速やかに対応致します。</p>
         <h4>広告の配信について</h4>
         <p>当サイトは第三者配信の広告サービス「Googleアドセンス」を利用する予定です（審査通過後は「利用しています」に変更してください）。</p>

         <p>Google などの第三者配信事業者は、Cookieを使用して、ユーザーがそのサイトや他のサイトにアクセスした際の情報に基づいて広告を配信します。</p>

         <p>また、広告配信事業者は、ユーザーがそのサイトや他のサイトにアクセスした際の情報に基づいて、ユーザーの興味に応じた広告を表示するためにCookieを使用することがあります。</p>

         <p>ユーザーはGoogleの<a href="https://adssettings.google.com/authenticated">広告設定</a>でパーソナライズ広告を無効にできるほか、<a href="https://optout.aboutads.info/?c=2&lang=EN">www.aboutads.info</a>にアクセスすれば、パーソナライズ広告に使われる第三者配信事業者のCookieを無効にできます。</p>

         <p>Googleアドセンスの詳細は「<a href="https://policies.google.com/?hl=ja">Googleポリシーと規約</a>」をご覧ください。</p>


         <h4>アクセス解析ツールについて</h4>
         <p>当サイトでは、Google Inc.が提供するアクセス解析ツール「Googleアナリティクス」を利用しています。<br />
            Googleアナリティクスは、トラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ます。</p>

         <p>Googleアナリティクスの詳細は「<a href="https://marketingplatform.google.com/about/analytics/terms/jp/">Googleアナリティクス利用規約</a>」をご覧ください。</p>


         <h3>著作権について</h3>
         <p>当サイトで掲載している画像は、「<a href="https://unsplash.com/">Unsplash</a>」または「<a href="https://www.photo-ac.com/">写真AC</a>」「<a href="https://www.canva.com/">Canva</a>」等の著作権フリー画像を使用しています（自分で撮影した写真や、自作または購入した画像、引用元が明記されている画像は除く）。</p>

         <p>記事の内容や掲載画像等に問題がある場合、各権利所有者様本人が直接メールでご連絡下さい。本人確認後、速やかに対応致します。</p>

         <p>また、当サイトのコンテンツ（記事・画像・その他プログラム）について、許可なく転載することを禁じます。引用の際は、当ブログへのリンクを掲載するとともに、転載であることを必ず明記してください。</p>
         <h3>注意事項</h3>
         <p>当サイトからリンクやバナーなどによって他のサイトに移動した場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。</p>

         <p>当サイトのコンテンツについて、可能な限り正確な情報を掲載するよう努めていますが、誤情報が入り込んだり、情報が古くなっている場合があります。当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。</p>


         <h3>プライバシーポリシーの変更について</h3>
         <p>当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。</p>

         <p>修正された最新のプライバシーポリシーは常に本ページにて開示されます。</p>

         <p>初出掲載：2025年1月14日</p>
         <br />
         <button onClick={clickBack}>＜Back＞</button>
         <button onClick={clickHome}>＜Home＞</button>
      </div >
   )
}

export default Policy