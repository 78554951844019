import { Legend } from "chart.js";

export const OPprem = {
   plugins: {
      title: {
         display: true,
         text: 'PREMIUM',
         font: {
            size: 12
         }
      },
   },
   responsive: true,
   maintainAspectRatio: false,/* for responsive*/
   spanGaps: true,
   scales: {
      x: {
         stacked: true,
         ticks: {
            font: {
               size: 10
            }

         },
         title: {
            display: true,
            text: 'Strike',
            font: {
               size: 12
            }
         }
      },
      y: {
         stacked: false,
         ticks: {
            font: {
               size: 10
            }
         },
         title: {
            display: true,
            text: 'Premium',
            font: {
               size: 12
            }
         }
      },
   },
};

export const OPIV = {
   plugins: {
      title: {
         display: true,
         text: 'IV',
         font: {
            size: 12
         }
      },
   },
   responsive: true,
   maintainAspectRatio: false,/* for responsive*/
   spanGaps: true,
   scales: {
      x: {
         stacked: true,
         ticks: {
            font: {
               size: 10
            }

         },
         title: {
            display: true,
            text: 'Strike',
            font: {
               size: 12
            }
         }
      },
      y: {
         stacked: false,
         ticks: {
            font: {
               size: 10
            }
         },
         title: {
            display: true,
            text: 'IV',
            font: {
               size: 12
            }
         }
      },
   },
};

export const OPDelta = {
   plugins: {
      title: {
         display: true,
         text: 'Delta',
         font: {
            size: 12
         }
      },
   },
   responsive: true,
   maintainAspectRatio: false,/* for responsive*/
   spanGaps: true,
   scales: {
      x: {
         stacked: true,
         ticks: {
            font: {
               size: 10
            }

         },
         title: {
            display: true,
            text: 'Strike',
            font: {
               size: 12
            }
         }
      },
      y: {
         stacked: false,
         ticks: {
            font: {
               size: 10
            }
         },
         title: {
            display: true,
            text: 'Delta',
            font: {
               size: 12
            }
         }
      },
   },
};

export const OPGamma = {
   plugins: {
      title: {
         display: true,
         text: 'Gamma',
         font: {
            size: 12
         }
      },
   },
   responsive: true,
   maintainAspectRatio: false,/* for responsive*/
   spanGaps: true,
   scales: {
      x: {
         stacked: true,
         ticks: {
            font: {
               size: 10
            }

         },
         title: {
            display: true,
            text: 'Strike',
            font: {
               size: 12
            }
         }
      },
      y: {
         stacked: false,
         ticks: {
            font: {
               size: 10
            }
         },
         title: {
            display: true,
            text: 'Gamma',
            font: {
               size: 12
            }
         }
      },
   },
};

export const OPTheta = {
   plugins: {
      title: {
         display: true,
         text: 'Theta(IV base)',
         font: {
            size: 12
         }
      },
   },
   responsive: true,
   maintainAspectRatio: false,/* for responsive*/
   spanGaps: true,
   scales: {
      x: {
         stacked: true,
         ticks: {
            font: {
               size: 10
            }

         },
         title: {
            display: true,
            text: 'Strike',
            font: {
               size: 12
            }
         }
      },
      y: {
         stacked: false,
         ticks: {
            font: {
               size: 10
            }
         },
         title: {
            display: true,
            text: 'Theta',
            font: {
               size: 12
            }
         }
      },
   },
};

export const OPVega = {
   plugins: {
      title: {
         display: true,
         text: 'Vega',
         font: {
            size: 12
         }
      },
   },
   responsive: true,
   maintainAspectRatio: false,/* for responsive*/
   spanGaps: true,
   scales: {
      x: {
         stacked: true,
         ticks: {
            font: {
               size: 10
            }

         },
         title: {
            display: true,
            text: 'Strike',
            font: {
               size: 12
            }
         }
      },
      y: {
         stacked: false,
         ticks: {
            font: {
               size: 10
            }
         },
         title: {
            display: true,
            text: 'Vega',
            font: {
               size: 12
            }
         }
      },
   },
};

export const OPresult = {
   plugins: {
      title: {
         display: true,
         text: 'Simulation Result',
         font: {
            size: 16
         },
         color: '#ccc',
      },
      legend: {
         labels: {
            color: '#ccc'
         }
      }
   },
   responsive: true,
   maintainAspectRatio: false,/* for responsive*/
   spanGaps: true,
   scales: {
      x: {
         min: -1500,
         max: 1500,
         stacked: true,
         ticks: {
            font: {
               size: 10
            },
            color: '#ccc',
         },
         grid: {
            color: function (context) {
               if (context.tick.value === 40) {
                  return '#aaa';
               } else {
                  return '#333';
               }
            }
         },
         title: {
            display: true,
            text: 'UnderLying Chg',
            font: {
               size: 12
            },
            color: '#ccc',
         },
         zeroLineColor: "black"
      },
      y: {
         stacked: false,
         ticks: {
            font: {
               size: 10
            },
            color: '#ccc',
         },
         grid: {
            color: function (context) {
               if (context.tick.value === 0) {
                  return '#aaa';
               } else {
                  return '#333';
               }
            }
         },
         title: {
            display: true,
            text: 'P/L',
            font: {
               size: 12
            },
            color: '#ccc',
         }
      },
   },
};

export const graphTmp = {
   labels: [],
   datasets: [
      {
         type: "line",
         label: "CALL",
         pointRadius: 2,
         borderColor: "rgb(231, 138, 76)",
         borderWidth: 1,
         fill: false,
         data: []
      },
      {
         type: "line",
         label: "PUT",
         pointRadius: 2,
         borderColor: "rgb(76, 159, 231)",
         borderWidth: 1,
         fill: false,
         data: []
      }
   ]
}

export const evalTmp = {
   labels: [],
   datasets: [
      {
         type: "line",
         label: "Result-real",
         pointRadius: 2,
         borderColor: "rgb(180, 180, 180)",
         borderWidth: 1,
         fill: false,
         data: []
      },
      {
         type: "line",
         label: "Date1",
         padding: 0,
         pointRadius: 2,
         borderColor: "rgb(118, 192, 217)",
         borderWidth: 1.5,
         fill: false,
         data: []
      },
      {
         type: "line",
         label: "Date2",
         pointRadius: 2,
         borderColor: "rgb(173, 239, 107)",
         borderWidth: 1.5,
         fill: false,
         color: '#ccc',
         data: []
      },
      {
         type: "line",
         label: "Date3",
         pointRadius: 2,
         borderColor: "rgb(230, 150, 10)",
         borderWidth: 1.5,
         fill: false,
         data: []
      },
      {
         type: "line",
         label: "SQ",
         pointRadius: 2,
         borderColor: "rgb(192, 65, 65)",
         borderWidth: 1.5,
         fill: false,
         data: []
      }
   ]
}

export const evalTmp_FreeTrade = {
   labels: [],
   datasets: [
      {
         type: "line",
         label: "Day1",
         pointRadius: 2,
         borderColor: "rgb(180, 180, 180)",
         borderWidth: 1,
         fill: false,
         data: []
      },
      {
         type: "line",
         label: "Day2",
         padding: 0,
         pointRadius: 2,
         borderColor: "rgb(118, 192, 217)",
         borderWidth: 1.5,
         fill: false,
         data: []
      },
      {
         type: "line",
         label: "Day3",
         pointRadius: 2,
         borderColor: "rgb(173, 239, 107)",
         borderWidth: 1.5,
         fill: false,
         data: []
      },
      {
         type: "line",
         label: "Day4",
         pointRadius: 2,
         borderColor: "rgb(230, 150, 10)",
         borderWidth: 1.5,
         fill: false,
         data: []
      },
      {
         type: "line",
         label: "Day5",
         pointRadius: 2,
         borderColor: "rgb(192, 65, 65)",
         borderWidth: 1.5,
         fill: false,
         data: []
      }
   ]
}