import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function About() {
   const navigate = useNavigate();

   const clickBack = () => {
      navigate(-1);//back to previous
   };
   const clickHome = () => {
      navigate("/");//go to Home
   };

   return (
      <div className='aboutus'>
         <h3 className='h1'>opsim.siteについて</h3>
         <p>
            opsim.siteは、証券業界でオプション取引の推進やシステム開発の経験を持つメンバが有志でチームで作り、投資家の皆様にとって理解しやすいツールを提供することを使命としています。<br />

            オプション取引は、その特性から理解が難しく、取引の際には慎重な判断が求められます。opsimt.siteは、損益シミュレーションを通じて、ユーザーがオプション取引のリスクと利益を簡単に把握できるように設計しています。<br />

            皆様がオプション取引をより安心して行えるよう、引き続き技術革新を追求し、サポートを提供してまいります。<br /><br />
            <a href="mailto:gutiriman3@gmail.com">お問合せ</a><br /><br />
         </p><br />
         <button onClick={clickBack}>＜Back＞</button>
         <button onClick={clickHome}>＜Home＞</button>
      </div >
   )
}

export default About